<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group" @click="searchModalVisible = true">
        <!--
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
        <button
          class="btn btn-link"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal"
        >
          <i class="tim-icons icon-zoom-split"></i>
        </button>
        <!-- You can choose types of search input -->
      </div>
      <modal
        :show.sync="searchModalVisible"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          id="inlineFormInputGroup"
          placeholder="SEARCH"
        />
      </modal>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
        <template slot="title">
          <div class="d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-cloud-upload-94"></i>
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li class="nav-link">
          <a
            @click="
              massUploadVisibility = true;
              massUploadType = 'student-athlete-transactions';
            "
            class="nav-item dropdown-item"
            >Mass Upload: Student Athlete Transactions -
            <span :style="{ 'font-weight': 'bold' }">{{
              studentAthleteDays
            }}</span>
          </a>
        </li>
        <li class="nav-link">
          <a
            @click="
              massUploadVisibility = true;
              massUploadType = 'general-funding';
            "
            class="nav-item dropdown-item"
            >Mass Upload: General Funding -
            <span :style="{ 'font-weight': 'bold' }">{{
              generalFundingDays
            }}</span></a
          >
        </li>
        <li class="nav-link">
          <a
            @click="
              massUploadVisibility = true;
              massUploadType = 'donation-funding';
            "
            class="nav-item dropdown-item"
            >Mass Upload: Donation Funding -
            <span :style="{ 'font-weight': 'bold' }">{{
              donationFundingDays
            }}</span></a
          >
        </li>
      </base-dropdown>
      <modal
        :show.sync="massUploadVisibility"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <card
          title="Horizontal Form"
          :style="{ background: 'none' }"
          v-if="formNotSubmited"
        >
          <h4 slot="header" class="card-title">
            Mass Upload - {{ massUploadTypeLabel }}
          </h4>
          <form class="form-horizontal" @submit.prevent="submitMassUploadForm">
            <div class="row">
              <label class="col-md-3 col-form-label">Mass Upload</label>
              <div class="col-md-9">
                <input
                  slot="header"
                  @change="handleFileChange"
                  type="file"
                  ref="uploadedFile"
                  class="form-control"
                  id="fileUpload"
                  placeholder="Mass Upload"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 offset-md-3 col-12">
                <base-button class="mt-3" native-type="submit" type="primary">
                  Upload
                </base-button>
              </div>
            </div>
          </form>
        </card>
        <div class="uploading" v-if="uploadingState">
          <img
            src="https://th.bing.com/th/id/R.dd8813278fc27c3abc4ff867dec088ad?rik=BpNsTBC2tlZrwQ&riu=http%3a%2f%2fwww.bis.org%2fimg%2fuploading.gif&ehk=5kNwsvjhzfdsILnc3cYTKnztk690Tk5M0n5in1WY7Z4%3d&risl=&pid=ImgRaw&r=0"
            alt=""
          />
          <p>Uploading ...</p>
        </div>
        <div class="success-message" v-if="displaySuccessMessage">
          <p>{{ uploadSuccessMessage }} - {{ massUploadTypeLabel }}</p>
        </div>
      </modal>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <div class="photo">
            <img :src="profileLogo" />
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none" @click="handleLogout">Log out</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Data Input</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Settings</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <p @click="handleLogout" class="nav-item dropdown-item">Log out</p>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    Modal,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    profileLogo() {
      // Make sure to add orginization to this
      return "https://lirp.cdn-website.com/b5f03e19/dms3rep/multi/opt/BPS+Logo-45w.png";
    },
    massUploadTypeLabel() {
      if (this.massUploadType === "student-athlete-transactions") {
        return "Student Athlete Transactions";
      } else if (this.massUploadType === "donation-funding") {
        return "Donation Funding";
      } else {
        return "General Funding";
      }
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      massUploadVisibility: false,
      searchQuery: "",
      fileUploaded: null,
      massUploadType: "",
      formNotSubmited: true,
      uploadingState: false,
      uploadSuccessMessage: "",
      displaySuccessMessage: false,
      studentAthleteDays: "",
      generalFundingDays: "",
      donationFundingDays: "",
    };
  },
  mounted() {
    this.getLatestUploads();
  },
  methods: {
    submitMassUploadForm() {
      if (!this.validateFile()) {
        return false;
      }

      this.formNotSubmited = false;
      this.uploadingState = true;
      const uploadUrl = `${process.env.VUE_APP_BASE_URL}/api/mass-upload/${this.massUploadType}`;
      const formData = new FormData();
      formData.append("file", this.fileUploaded);
      console.log(this.fileUploaded);
      this.$http
        .post(uploadUrl, formData)
        .then((res) => {
          this.uploadSuccessMessage = res.data.message;
          (this.uploadingState = false), (this.displaySuccessMessage = true);
        })
        .catch((err) => console.log(err));
    },
    getLatestUploads() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/mass-upload/latest`)
        .then((res) => {
          this.handleLatestUploads(res.data);
        })
        .catch((err) => console.log(err));
    },
    handleLatestUploads(data) {
      const studentAthlete = new Date(data.student_athlete_transactions);
      const generalFunding = new Date(data.general_funding);
      const donationFunding = new Date(data.donation_funding);
      const currentDate = new Date();

      this.studentAthleteDays =
        studentAthlete.toDateString() == currentDate.toDateString()
          ? "Today"
          : this.handleDate(studentAthlete);
      this.generalFundingDays =
        generalFunding.toDateString() == currentDate.toDateString()
          ? "Today"
          : this.handleDate(generalFunding);
      this.donationFundingDays =
        donationFunding.toDateString() == currentDate.toDateString()
          ? "Today"
          : this.handleDate(donationFunding);
    },
    handleDate(date) {
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - date.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

      if (daysDifference > 1) {
        return `${daysDifference} days`;
      } else {
        return `${daysDifference} day`;
      }
    },
    handleLogout() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/auth/logout`)
        .then((res) => {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login");
          });
        });
    },
    validateFile() {
      if (!this.fileUploaded) {
        alert("Please select a file");
        return false;
      }
      if (this.fileUploaded.type !== "text/csv") {
        alert("Please ensure the file is a CSV");
        return false;
      }

      return true;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.fileUploaded = file;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
